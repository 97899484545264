<template>
  <button
      ref="button"
      :class="[size,disabled ? 'disabled' : '']"
      v-touch:start="onTapStart"
      v-touch:end="onTapEnd"
  >{{value}}</button>
</template>

<script>
export default {
  name: "ButtonPink",
  props:{
    size: String,
    value: String,
    disabled: Boolean
  },
  methods:{
    onTapStart(){
      this.$refs.button.style.filter = 'brightness(.9)'
    },
    onTapEnd(){
      this.$refs.button.style.filter = 'brightness(1)'
    }
  }
}
</script>

<style scoped lang="scss">
button{
  border: 0;
  outline: 0;
  border-radius: 25.5px;
  cursor: pointer;
  background: #6099FC;

  &:hover,&:active{
    //background: rgba(34, 34, 34, 0.10) linear-gradient(-42deg, #E83F94 0%, #F54E5E 100%);
    filter: brightness(.9);
  }
}

.xs{
  width: 180px;
  height: 40px;
  font-family: Roboto, "sans-serif";
  font-weight: 900;
  font-size: 18px;
  color: #FFFFFF;
  letter-spacing: 1px;
  text-align: center;
}

.md{
  width: 290px;
  height: 51px;
  font-family: Roboto, "sans-serif";
  font-weight: 900;
  font-size: 18px;
  color: #FFFFFF;
  letter-spacing: 1px;
  text-align: center;
}

.lg{
  width: 305px;
  height: 51px;
  font-family: Roboto, "sans-serif";
  font-weight: 900;
  font-size: 18px;
  color: #FFFFFF;
  letter-spacing: 1px;
  text-align: center;
}

.sm{
  width: 200px;
  height: 51px;
  font-family: Roboto, "sans-serif";
  font-weight: 900;
  font-size: 18px;
  color: #FFFFFF;
  letter-spacing: 1px;
  text-align: center;
}


.disabled{
  opacity: 0.5;
  font-size: 18px;
  color: #69748C;
  background: #F9F9F9;
  border: 1px solid #EBEDEE;
  pointer-events: none;
  cursor: none;
}
</style>
